import Vuex from 'vuex';
// import { REALMS_LIST } from '@/services/config';

const realms = new Vuex.Store({
  state: {
    realmsList: [],
  },
  mutations: {
    setRealmsList(state, realms) {
      state.realmsList = realms;
    },
  },
  actions: {
    async fetchRealmsList({ commit }) {
      try {
        // const apiUrl = REALMS_LIST || 'https://stg.stattus4.com/api/realmsList';
        const apiUrl = 'https://stg.stattus4.com/api/realmsList';
        const response = await fetch(apiUrl);
        const data = await response.json();
        localStorage.setItem('realms-list-stt4', data)
        commit('setRealmsList', data);
      } catch (error) {
        console.error('Erro ao buscar realms:', error);
      }
    },
  },
  getters: {
    realmsList: (state) => state.realmsList,
  },
});

export default realms;
