<template>
  <Toast />
  <div class="alternative">
    <div class="btnSel" v-if="isSuper">
      <router-link :class="{ 'btn me-2 btn-filtrar': $route.path === '/' }" class="btn me-2 btn-filtrar"
        to="/listagem/clientes">
        <div class="marginDropdownOptions"></div>
        Admin
      </router-link>
    </div>
    <div class="btnSel" v-else>
      <router-link :class="{ 'btn btn-filtrar me-2 ': $route.path === '/' }" class="btn btn-filtrar me-2"
        to="/listagem/comissionamento">
        <div class="marginDropdownOptions"></div>
        Admin
      </router-link>
    </div>
    <div class="btnSel">
      <router-link :class="{ 'btn btn-filtrar me-2 ': $route.path === '/' }" class="btn btn-filtrar me-2"
        to="/alterarSenha">
        <div class="marginDropdownOptions"></div>
        Alterar Senha
      </router-link>
      <router-link :class="{ 'btn btn-filtrar me-2 ': $route.path === '/' }" class="btn btn-filtrar me-2"
        to="/parametrosConfig">
        <div class="marginDropdownOptions"></div>
        Parâmetros de Configuração
      </router-link>
    </div>
    <div class="btnSel">
    </div>
  </div>
  <div class="allScreen" id="dashContent">
    <div v-show="showNav">
      <button v-if="this.controlSidebar" class="openbtn_inicio" @click="controleCollpaseFiltro()">
        <i class="gg-push-chevron-left"></i>
      </button>
      <button v-else class="closebtn_inicio" @click="controleCollpaseFiltro()">
        <i class="gg-push-chevron-right"></i>
      </button>
    </div>
    <div :class="showNav ? 'col-sm-2 pontosInstall sidebar' : 'col-sm-2 pontosInstall sidebar-hidden-nav'" id="mySidebar">
      <div class="filter-container">
        <FiltroSensores :typeSensors="iconDevicesClient" @FiltraSensor="selectSensor" :verifyType="checkTypes" />
        <div v-show="checkTypes && empresa" class="btn-group" role="group" aria-label="Basic radio toggle button group">
          <input type="radio" class="btn-check" id="btnradioMedicao" name="btnradio" value="medicao"
            v-model="medAlarme" />
          <label for="btnradioMedicao" class="btn btn-outline-success btn-router"> Medição </label>

          <input id="btnradioAlarmes" type="radio" class="btn-check" name="btnradio" value="alarmes"
            v-model="medAlarme" :disabled="checkTypes === 'macro'"/>
          <label for="btnradioAlarmes" class="btn btn-outline-success btn-router"> Alarmes </label>
        </div>
        <div v-show="checkTypes && empresa">
          <v-date-picker v-show="medAlarme === 'medicao'" v-model="range" :model-config="modelConfig" mode="date" is24hr
            is-range color="green" :popover="{ visibility: 'click' }">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <div class="textDate">Data:</div>
                <input :value="inputValue.start + ' - ' + inputValue.end" v-on="inputEvents.start"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300 input-data" />
                <div style="display: none">
                  <div class="textDate">Até:</div>
                  <input :value="inputValue.end" v-on="inputEvents.end"
                    class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300" />
                </div>
              </div>
            </template>
          </v-date-picker>
        </div>
        <div v-show="medAlarme === 'alarmes'">
          <div style="margin-top:1rem">
            <FiltroAlarmes :checkTypeSelect="checkTypes" @FiltraAlarmes="checkAlarmsPress"/>
          </div>
        </div>
        <div style="display: flex; justify-content: space-evenly;">
          <div class="col-sm-6" v-show="checkTypes && empresa">
            <button class="btn btn-filtrar submit" type="button" @click="filterDevicesByAlarmsOrReadings()">
              Filtrar
            </button>
          </div>
          <div class="col-sm-6" v-show="checkTypes && empresa">
            <button class="btn btn-limpar submit" type="button" @click="resetFilter()">
              Limpar
            </button>
          </div>
        </div>
        <div v-show="checkTypes && empresa">
          <div v-if="this.selectedCountries && medAlarme == 'medicao'">
            <div v-show="checkTypes === 'flow'">
              <div v-if="this.products.length > 0">
                <button class="btn btn-pos_filtrar" type="button" @click="resultAgreg">
                  Resultado Agregado
                </button>
              </div>
            </div>
            <div v-if="this.products.length > 0">
              <CompRelatorios :date_start="this.range.start" :date_end="this.range.end" :typeDevice="checkTypes"
                :resetRel="reset" :flagScreen="true" @FiltraRelatorios="selectSensor" />
            </div>
          </div>
        </div>
      </div>

      <div class="collapseFilterClass" @click="collapseFilter">
        <button class="gg-select-o marginIcon"></button>
      </div>
      <div>
        <div class="col-sm-12">
          <div style="margin-top: 15px">
            <input type="radio" name="aaa" value="name" v-model="radioFilter" @click="cleanFilter()">
            <label for="" style="margin-right: 20px;">Nome</label>
            <input type="radio" name="aaa" value="tags" v-model="radioFilter">
            <label for="">Tag</label>
            <div v-if="radioFilter === 'tags'"
              style="display: flex;flex-wrap: wrap;justify-content:start;padding: 15px">
              <div v-for="f_tag in listTags" :key="f_tag.id_name">
                <span v-if="f_tag.selected" class="badge rounded-pill"
                  :style="f_tag.color != 'yellow' && f_tag.color != 'aqua' && f_tag.color != 'pink'
                    ? `color: white;background-color: ${f_tag.color}; border: 1px solid;font-size: 14px;margin-bottom: 5px;`
                    : `color: black;background-color: ${f_tag.color};font-size: 14px;margin-bottom: 5px; cursor:pointer;`" @click="selectMonomer(f_tag)">
                  {{ f_tag.name }}
                </span>
                &nbsp;

                <span v-if="!f_tag.selected" class="badge rounded-pill"
                  :style="`color: ${f_tag.color};border-color: ${f_tag.color}; border: 1px solid;font-size: 14px;margin-bottom: 5px; cursor:pointer;`"
                  @click="selectMonomer(f_tag)">
                  {{ f_tag.name }}
                </span>
                &nbsp;
              </div>
            </div>
          </div>
          <Listbox v-model="this.selectedCountries" class="listBox" id="listBoxID" :options="products"
            :optionLabel="radioFilter" emptyMessage="Sem Dados" emptyFilterMessage="Nenhum dado encontrado"
            style="width: 100%" :filter="radioFilter == 'tags' ? false : true" ref="filter"
            @click="showResultAgreg = false"
            :optionDisabled="(option) => option.device_id == this.selectedCountries.device_id">
            <template #option="slotProps">
              <div class="country-item">
                <div class="numPontoInstal">
                  <div class="fontListPontoInstall" id="my-listBox">
                    {{ slotProps.option.name }}
                  </div>
                  <div class="device-type-icons-container">
                    <span>
                      <img v-if="slotProps.option.type === 'flow'" src='../assets/flowOff.png' alt="device flow icon"
                        class="device-type-icon">
                      <img v-else-if="slotProps.option.type === 'pressure'" src='../assets/pressureOff.png'
                        alt="device pressure icon" class="device-type-icon">
                        <img v-else-if="slotProps.option.type === 'macro'" src='../assets/macroOff.png' alt="device macrometer icon"
                        class="device-type-icon">
                      <img v-else src='../assets/noiseOff.png' alt="device noise icon" class="device-type-icon">
                    </span>
                  </div>
                </div>
                <div v-if="slotProps.option.tags">
                  <div style="display: flex;flex-wrap: wrap;">
                    <div v-for="tag in slotProps.option.tags" :key="tag.id">
                      <span class="badge rounded-pill" data-bs-toggle="tooltip" data-bs-placement="bottom"
                        :title="tag.name"
                        :style="tag.color != 'yellow' && tag.color != 'aqua' && tag.color != 'pink' ? `background-color: ${tag.color}; color: white; ` : `background-color: ${tag.color}; color: black; `">
                        {{ tag.name }}
                      </span>
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Listbox>
        </div>
      </div>
    </div>
    <div v-if="!this.selectedCountries.name && !this.showResultAgreg" class="col-sm-12 main_header" id="main_header">
      <div class="col-sm-12" style="padding-top: 1rem;"></div>
      <div v-if="!verifyDate" style="display: flex; margin-inline: auto;">
        <div v-if="allAlarmsCount.length > 0" style="display: flex; margin-inline: auto">
          <Card class="box_indicadores cardStyle" style="cursor: pointer;">
            <template #title>
              Pressão
            </template>
            <template #content>
              <div style="display:flex; justify-content: space-around">
                <img src="../assets/pressureOff.png" width="55" height="55">
                <p style="font-size: 27px;place-self: end;"> {{ pressureDevicesCommissioning }} / {{
                  countDevicesCommissioning }}</p>
              </div>
            </template>
          </Card>
          <Card class="box_indicadores cardStyle" style="cursor: pointer;">
            <template #title>
              Vazão
            </template>
            <template #content>
              <div style="display:flex; justify-content: space-around">
                <img src="../assets/flow_Off.png" width="55" height="55">
                <p style="font-size: 27px;place-self: end;"> {{ flowDevicesCommissioning }} / {{
                  countDevicesCommissioning }}</p>
              </div>
            </template>
          </Card>
        </div>
        <!-- <div v-if="allAlarmsCount.length > 0" style="display: flex; margin-inline: auto">
          <Card
            class="box_indicadores cardStyle"
            style="cursor: pointer;"
            v-for="item in allAlarmsCount"
            :key="item"
            @click="getCardsAlarms(item)"
          >
            <template #title>
              {{ item.name }}
            </template>
            <template #content>
              <div style="display:flex; justify-content: space-around">
                <img v-if="item.name === 'Fraude' && item.value > 0" src="../assets/alarmes/icone_fraude.png" width="55"
                  height="55">
                <img v-if="item.name === 'Baixa Comunicação' && item.value > 0"
                  src="../assets/alarmes/icone_baixa_comunicacao.png" width="55" height="55">
                <img v-if="item.name === 'Vazamento Interno' && item.value > 0"
                  src="../assets/alarmes/icone_vazamento_interno_vermelho.png" width="55" height="55">
                <img v-if="item.name === 'Hidrômetro Parado' && item.value > 0"
                  src="../assets/alarmes/icone_hdro_parado_vermelho.png" width="55" height="55">

                <img v-if="item.name === 'Fraude' && item.value === 0" src="../assets/alarmes/icone_fraude_pb.png"
                  width="55" height="55">
                <img v-if="item.name === 'Baixa Comunicação' && item.value === 0"
                  src="../assets/alarmes/icone_baixa_comunicacao_pb.png" width="55" height="55">
                <img v-if="item.name === 'Vazamento Interno' && item.value === 0"
                  src="../assets/alarmes/icone_vazamento_interno.png" width="55" height="55">
                <img v-if="item.name === 'Hidrômetro Parado' && item.value === 0"
                  src="../assets/alarmes/icone_hdro_parado.png" width="55" height="55">

                <p style="font-size: 40px;place-self: end;"> {{ item.value }}</p>
                <p v-if="item.name === 'Fraude'" style="font-size: 20px;place-self: end;">/
                  {{ countDevicesCommissioning }}</p>
                <p v-if="item.name === 'Baixa Comunicação'" style="font-size: 20px;place-self: end;">/
                  {{ countDevicesCommissioning }}</p>
                <p v-if="item.name === 'Vazamento Interno'" style="font-size: 20px;place-self: end;">/
                  {{ flowDevicesCommissioning }}</p>
                <p v-if="item.name === 'Hidrômetro Parado'" style="font-size: 20px;place-self: end;">/
                  {{ flowDevicesCommissioning }}</p>
              </div>
            </template>
          </Card>
        </div> -->
        <div v-else style="display: flex; margin-inline: auto">
          <Card class="box_indicadores cardStyle" v-for="item in 2" :key="item">
            <template #title>
              ...
            </template>
          </Card>
        </div>
      </div>
      <div class="col-sm-12" v-if="showMap">
        <GMapMap :class="!verifyDate ? 'mmm' : 'mmm_b'" ref="myMapRef" :center="center" :zoom="15" map-type-id="terrain"
          :options="options">
          <GMapMarker :key="marker.index" v-for="marker in places" :position="{ lat: marker.lat, lng: marker.lng }"
            @click="filterMap(marker)" :icon="marker.type === 'flow' ? markerOptionsB : marker.type === 'macro' ? markerOptionsBSky : markerOptionsBk"
            :clickable="true" />
          <GMapCircle :key="marker.index" v-for="marker in places" :radius="Math.sqrt(0.1) * 10"
            :center="{ lat: marker.latPair, lng: marker.lngPair }"
            :options="marker.flagGroup ? circleOptionsTrue : circleOptionsFalse" />
        </GMapMap>
      </div>
    </div>
    <!-- PONTO DE INSTALAÇÃO SELECIONADO -->
    <div v-else-if="showResultAgreg === true" class="col-sm-12 main_header">
      <div class="col-sm-12 mapaGrafico" id="resultadoAgregado">
        <div class="col-sm-6 mapaDisplay">
          <GMapMap class="mmm mapaResultadoAgregado" ref="myMapRef" :center="center" :zoom="15" map-type-id="terrain"
            :options="options">
            <GMapMarker v-for="marker in dataMapAgreg" :key="marker.index"
              :position="{ lat: marker.lat, lng: marker.lng }" :icon="markerOptionsB" />
            <GMapCircle :key="marker.index" v-for="marker in places" :radius="Math.sqrt(0.1) * 10"
              :center="{ lat: marker.latPair, lng: marker.lngPair }"
              :options="marker.flagGroup ? circleOptionsTrue : circleOptionsFalse" />
          </GMapMap>
        </div>

        <div class="col-sm-6 mapaDisplay">
          <ScrollPanel class="customResultadoAgregado">
            <CompResultadoAgregado :data_inicial="this.range.start" :data_final="this.range.end"
              :device_list="products" />
          </ScrollPanel>
        </div>

      </div>
    </div>
    <div v-else-if="showResultAgreg === false" class="col-sm-12 main_header">
      <div class="col-sm-6" id="info-pi">
        <div style="display:flex">
          <!-- <Card class="box_indicadores cardStyle" id="cardAlarmes">
            <template #title>
              Alarmes
              <div v-for="alarm in selectedCountries.alarms" :key="alarm.name_id">
                <img v-tooltip.top="'Fraude'" v-if="alarm.name_id === 'tampering'" src="../assets/alarmes/icone_fraude.png" width="50" height="50">
                <img v-tooltip.top="'Sensor Removido'" v-if="alarm.name_id === 'removal'" src="../assets/alarmes/icone_sensor_removido_vermelho.png" width="50" height="50">
                <img v-tooltip.top="'Corte de Cabo'" v-if="alarm.name_id === 'cable-cut'" src="../assets/alarmes/icone_corte_de_cabo_vermelho.png" width="50" height="50">
                <img v-tooltip.top="'Fluxo Reverso'" v-if="alarm.name_id === 'reverse-flow'" src="../assets/alarmes/icone_fluxo_reverso_vermelho.png" width="50" height="50">
                <img v-tooltip.top="'Hidrômetro Parado'" v-if="alarm.name_id === 'meter-locked'" src="../assets/alarmes/icone_hdro_parado_vermelho.png" width="50" height="50">
                <img v-tooltip.top="'Vazamento Interno'" v-if="alarm.name_id === 'direct-leakage'" src="../assets/alarmes/icone_vazamento_interno_vermelho.png" width="50" height="50">
                <img v-tooltip.top="'Fluxo Reverso'" v-if="alarm.name_id === 'reverse-leakage'" src="../assets/alarmes/icone_fluxo_reverso_vermelho.png" width="50" height="50">
              </div>
            </template>
          </Card> -->
          <Card v-if="selectedCountries.type != 'macro'"  class="box_indicadores cardStyle">
            <template #title>
              Nível de Bateria
            </template>
            <template #content>
              <img v-if="selectedCountries.battery_level == 'Ok'" src="../assets/battery-full.png"
                class='levelBat flag flag-'>
              <img v-if="selectedCountries.battery_level == 'Regular'" src="../assets/battery.png"
                class='levelBat flag flag-'>
              <img v-if="selectedCountries.battery_level == 'Baixa'" src="../assets/battery-empty.png"
                class='levelBat flag flag-'>
              <span style="margin: 0.3rem 1.5rem;">
                {{ selectedCountries.battery_level == 'Não informado' ? "Sem dados" : '' }}
              </span>
            </template>
          </Card>
          <Card class="box_indicadores cardStyle">
            <template #title>
              Código de Instalação
            </template>
            <template #content>
              <div class="gridInst">
                <div class="codInst_grid">{{ selectedCountries.name }}</div>
                <img v-if="selectedCountries.type == 'pressure'" src="../assets/pressure_Off.png" class='type_grid flag flag-'>
                <img v-if="selectedCountries.type == 'flow'" src="../assets/flow_Off.png" class='type_grid flag flag-'>
                <img v-if="selectedCountries.type == 'noise'" src="../assets/noise_Off.png" class='type_grid flag flag-'>
                <img v-if="selectedCountries.type == 'macro'" src="../assets/macro_Off.png" class='type_grid flag flag-'>
                <button v-if="selectedCountries.type != 'macro'" class="btn btn-detalhamento btnDetail_grid" label="Show" @click="openBasicNew()" type="button">
                  Detalhar
                </button>
              </div>
            </template>
          </Card>

        </div>
      </div>
      <div class="col-sm-12 mapaGrafico">
        <div class="col-sm-6 mapaDisplay">
          <GMapMap :class="selectedCountries.type == 'macro' ? 'map-device-selecetd-macro' : 'map-device-selecetd'" ref="myMapRef"
            :center="{ lat: this.selectedCountries.lat, lng: this.selectedCountries.lng }" :zoom="25"
            map-type-id="terrain" :options="options">
            <GMapMarker v-for="marker in places" :key="marker.index" :position="{ lat: marker.lat, lng: marker.lng }"
              @click="filterMap(marker)" :icon="marker.type === 'flow' ? markerOptionsB : marker.type === 'macro' ? markerOptionsBSky : markerOptionsBk"
              :animation="marker.device_id == this.selectedCountries.device_id ? 1 : 0"
              :clickable="marker.device_id !== this.selectedCountries.device_id ? true : false" />
            <GMapCircle :key="marker.index" v-for="marker in places" :radius="Math.sqrt(0.1) * 10"
              :center="{ lat: marker.latPair, lng: marker.lngPair }"
              :options="marker.flagGroup ? circleOptionsTrue : circleOptionsFalse" />
          </GMapMap>
          <!-- Div com o Heatmap -->
          <div class="tabs" v-if="selectedCountries.type != 'macro'">
            <TabView :lazy="true" v-model:activeIndex="tabIndex">
              <TabPanel header="Conectividade">
                <div>
                  <CalHeatMaps :data-inicial="this.range.start" :dataF="this.dataF" :dataN="this.dataN"
                    :dataP="this.dataP" :dataM="this.dataM"  :selectedDevice="this.selectedCountries" :tabX="tabName" />
                </div>
              </TabPanel>
              <TabPanel header="Log de Telemetria">
                <LogTel :selectedDevice="this.selectedCountries" />
              </TabPanel>
              <TabPanel header="Aviso" v-if="tabIndex == 3">
                <div style="font-size: 20px; text-align: center;">
                  Não há aviso de alarme disparado.
                </div>
              </TabPanel>
            </TabView>
          </div>
        </div>
        <div class="col-sm-6 graficoDisplay">
          <ScrollPanel class="custom">
            <charts :data-inicial="this.range.start" :data-final="this.range.end"
              :selectedDevice="this.selectedCountries" :upGrafico="this.graficEvent">
            </charts>
          </ScrollPanel>
        </div>
      </div>

      <Toast />
      <Toast position="top-left" group="tl" />
      <CompDetalhamento :show="displayBasic" :selectedDevice="selectedCountries" :arrTags="listTags" />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import "v-calendar/dist/style.css";
import Listbox from "primevue/listbox";
import { store } from "../services/store.js";
import {
  commmunication,
  alarmsCount,
  installPointsData,
  getFilterDevicesByAlarmsOrReadings,
  getBatteryAndAlarms
} from "../services/dashboard.js";
import ScrollPanel from "primevue/scrollpanel";
import moment from "moment-timezone";
import Charts from "../components/Charts.vue";
import CalHeatMaps from "../components/CalHeatMaps.vue";
import LogTel from "../components/LogTel.vue";
moment.locale("en-us");
import { useLoading } from "vue-loading-overlay";
const $loading = useLoading();
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import Card from 'primevue/card';
import FiltroSensores from "../components/FiltroSensores.vue";
import FiltroAlarmes from "../components/FiltroAlarmes.vue";
import CompRelatorios from "../components/CompRelatorios.vue";
import CompResultadoAgregado from "../components/CompResultadoAgregado.vue";
import CompDetalhamento from "../components/CompDetalhamento.vue";

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { listTag } from "../services/crud.js";
import _ from 'lodash';
import { CLIENTS_LIST_TAG_SELECTED } from "@/services/config.js";

import { StyleMap, StyleMapFair } from "../utils/StyleMap";

const mapMarkerBk = require("../assets/pinBk.png");
const mapMarkerR = require("../assets/pinR.png");
const mapMarkerB = require("../assets/pinBFull.png");
const mapMarkerBSky = require("../assets/pinBSky.png");

export default defineComponent({
  name: "Dashboard",
  components: {
    Listbox,
    ScrollPanel,
    Toast,
    Charts,
    TabView,
    TabPanel,
    CalHeatMaps,
    LogTel,
    Card,
    FiltroSensores,
    FiltroAlarmes,
    CompRelatorios,
    CompResultadoAgregado,
    CompDetalhamento,
  },
  data() {
    return {
      showMap: false,
      iconDevicesClient: [],
      flagDate: false,
      verifyDate: false,
      pressReset: false,
      countDevicesCommissioning: null,
      flowDevicesCommissioning: null,
      pressureDevicesCommissioning: null,
      allFrauds: null,
      checkMulti: false,
      alarmsAllResp: null,
      dataMapAgreg: null,
      showResultAgreg: false,
      reset: false,
      checkTypes: null,
      checkTypesAlarms: [],
      collapseFiltro: true,
      allAlarmsCount: [],
      auxTags: [],
      tempMeter: null,
      countTagCall: 0,
      listTags: [],
      radioFilter: "name",
      isSuper: store.type_user == 1 ? true : false,
      displayBasic: false,
      markerOptionsBk: {
        url: mapMarkerBk,
        scaledSize: { width: 30, height: 30, f: "px", b: "px" },
      },
      markerOptionsR: {
        url: mapMarkerR,
        scaledSize: { width: 30, height: 30, f: "px", b: "px" },
      },
      markerOptionsB: {
        url: mapMarkerB,
        scaledSize: { width: 30, height: 30, f: "px", b: "px" },
      },
      markerOptionsBSky: {
        url: mapMarkerBSky,
        scaledSize: { width: 30, height: 30, f: "px", b: "px" },
      },
      circleOptionsTrue: {
        strokeColor: "#6eff94",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#6edd94",
        fillOpacity: 0.35,
      },
      circleOptionsFalse: {
        strokeColor: "#fff",
        strokeOpacity: 0.01,
        strokeWeight: 2,
        fillColor: "#fff",
        fillOpacity: 0.01,
      },
      center: { lat: -23.4775, lng: -47.4241 },
      rangeBack: {},
      range: {
        start: this.aMonthAgo(),
        end: new Date(),
      },
      medAlarme: "",
      periodo: "1",
      graficoHora: 1,
      products: [],
      backupInstallPointsData: [],
      empresa: null,
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
        timeAdjust: "12:00:00",
      },
      places: [],
      controlSidebar: false,
      selectedCountries: {
        name: "",
        id: null,
        device_id: null,
        flagGroup: null,
        index: null,
        lat: null,
        latPair: null,
        lng: null,
        lngPair: null,
        tags: [],
        type: null,
      },
      rangeStart: { start: this.aMonthAgo(), end: new Date() },
      temp: null,
      flag: null,
      graficEvent: 0,
      dataF: null,
      dataN: null,
      dataP: null,
      dataM: null,
      tabIndex: 0,
      tabName: null,
      tabF: null,
      tabN: null,
      tabP: null,
      tabM: null,
      newTagsUp: {},
      options: {
        styles: JSON.parse(localStorage.getItem("userData")).demo ? StyleMapFair : StyleMap,
      },
      noSectorArr : [
          {
              "id_name": "ntag",
              "name": "NTAG",
              "color": "black"
          }
      ]
    };
  },
  methods: {
    resetSelectedCountries() {
      this.selectedCountries = {
        name: "",
        id: null,
        device_id: null,
        flagGroup: null,
        index: null,
        lat: null,
        latPair: null,
        lng: null,
        lngPair: null,
        tags: [],
        type: null,
      }
    },
    controleCollpaseFiltro() {
      let tempAnimTime = 300;
      this.sidebarControl = {
        animationTime: tempAnimTime,
        frameInterval: 10,
        width: 250,
        iterations: null,
        diffPerIteration: null,
      };
      this.sidebarControl.iterations = Math.floor(this.sidebarControl.animationTime / this.sidebarControl.frameInterval);
      this.sidebarControl.diffPerIteration = this.sidebarControl.width / this.sidebarControl.iterations;
      this.controlSidebar ? this.closeNav() : this.openNav();
    },
    openNav() {
      this.sidebarControl.animationTime = 30;
      document.getElementById("mySidebar").style.width = "290px";
      let elapsedTime = 0;
      document.getElementById("mySidebar").style.width = "290px";

      let animationInterval = setInterval(() => {
        elapsedTime += this.sidebarControl.frameInterval;
        if (elapsedTime > this.sidebarControl.animationTime) {
          clearInterval(animationInterval);
          document.getElementById("dashContent").style.marginLeft = "290px";
          this.controlSidebar = true;
          this.emitter.emit('my-event-controlSidebar', true);
          return;
        }
        const currentMarginLeft = parseFloat(document.getElementById("dashContent").style.marginLeft.replace('px', ''));
        document.getElementById("dashContent").style.marginLeft = (currentMarginLeft + this.sidebarControl.diffPerIteration).toString() + 'px';
      }, this.sidebarControl.frameInterval);
    },
    closeNav() {
      document.getElementById("mySidebar").style.width = "0";
      let elapsedTime = 0;
      let animationInterval = setInterval(() => {
        elapsedTime += this.sidebarControl.frameInterval;
        if (elapsedTime > this.sidebarControl.animationTime) {
          clearInterval(animationInterval);
          document.getElementById("dashContent").style.marginLeft = "0";
          this.controlSidebar = false
          this.emitter.emit('my-event-controlSidebar', false)
          return;
        }
        const currentMarginLeft = parseFloat(document.getElementById("dashContent").style.marginLeft.replace('px', ''));
        document.getElementById("dashContent").style.marginLeft = (currentMarginLeft - this.sidebarControl.diffPerIteration).toString() + 'px';
      }, this.sidebarControl.frameInterval);
    },
    collapseFilter() {
      this.collapseFiltro = !this.collapseFiltro
      var element = document.getElementsByClassName("filter-container");
      if (this.collapseFiltro) {
        element[0].style.display = "block";
      } else {
        element[0].style.display = "none";
      }
    },
    geolocate() {
      if (!navigator.geolocation) return;
      navigator.geolocation.getCurrentPosition((position) => {
        this.center.lat = position.coords.latitude;
        this.center.lng = position.coords.longitude;
      });
    },
    openBasicNew() {
      this.displayBasic = !this.displayBasic;
    },
    centralize() {
      const loader = $loading.show({});
      if (this.$refs.myMapRef) {
        this.$refs.myMapRef.$mapPromise.then(() => {
          let bounds = new window.google.maps.LatLngBounds();
          Object.values(this.places).forEach((sample) =>
            bounds.extend({ lat: sample.lat, lng: sample.lng })
          );
          this.$refs.myMapRef.$mapPromise
            .then((map) => {
              map.panTo(bounds.getCenter());
              map.fitBounds(bounds);
            })
            .catch(function (e) {
              console.log("message", e);
            });
        });
      }

      loader.hide();
    },
    centralizeCards(ipDataArray) {
      this.places = ipDataArray.map((ipDataObj) => ({
        lat: ipDataObj.lat,
        lng: ipDataObj.lng,
        id: ipDataObj.id,
        name: ipDataObj.name,
        tags: ipDataObj.tags,
      }));

      this.centralize();
    },
    verifyDay() {
      let antes = moment(this.range.start);
      let depois = moment(this.range.end);
      let dias = depois.diff(antes, "days");

      if (dias > 30) {
        this.graficoHora = 1;
        this.periodo = "1";
        alert('Atenção! Data inicial e final superior a 30 dias. Só é permitido intervalo igual ou menor a 30 dias.');
        this.range = {
          start: this.aMonthAgo(),
          end: new Date()
        }
        return false;
      } else {
        this.graficoHora = 1;
        this.periodo = "1";
        return true;
      }
    },
    async filterMap(data, flagDate) {
      const loader = $loading.show({});
      this.showMap = true;
      this.emitter.emit('event-show-multiselect', false);

      this.showResultAgreg = false;

      if (flagDate) {
        this.verifyDate = true;
      }

      if (this.isSuper && store.last_search.length > 0) {
        this.getAlarmsCount(store.last_search);
      } else {
        this.getAlarmsCount(this.empresa ? this.empresa : store.client_id);
      }

      this.listTags.forEach((tag) => {
        this.auxTags = [];
        tag.selected = false;
        return
      })

      if (this.$refs.filter) {
        this.$refs.filter.filterValue = "";
      }

      if (this.empresa == null) {
        this.empresa = store.client_id;
      }

      // Entrando direto pelo email Inicio
      if (this.isSuper && store.last_search.length > 0) {
        this.empresa = store.last_search;
      } else if (this.isSuper != 1) {
        this.empresa = store.client_id;
      }

      // Fim
      if (!data) {
        const client_id = this.empresa;
        store.id = this.empresa;
        let payload = { client_id };

        await installPointsData(payload)
          .then(async (response) => {
            let list_points = response.data;
       
            this.iconDevicesClient = [...new Set(_.cloneDeep(response.data.map(({ type, macrometer }) => macrometer ? 'macro' : type)))];

            if (list_points.length) {
              this.places = [];
              const ipAlreadyAccounted = new Set()

              for (let i in list_points) {
                const ipData = list_points[i];
                const repeatedIP = ipAlreadyAccounted.has(ipData.install_point_id);

                ipData.lat = repeatedIP
                  ? (parseFloat(`${ipData.lat}`.substr(0, 9)) + 0.000004)
                  : (parseFloat(`${ipData.lat}`.substr(0, 9)) - 0.000004);

                ipData.long = repeatedIP
                  ? (parseFloat(`${ipData.long}`.substr(0, 9)) + 0.000004)
                  : (parseFloat(`${ipData.long}`.substr(0, 9)) - 0.000004);
                  this.places.push({
                    device_id: ipData.device_id,
                    latPair: parseFloat(ipData.lat),
                    lngPair: parseFloat(ipData.long),
                    flagGroup: repeatedIP,
                    lat: ipData.lat,
                    lng: ipData.long,
                    id: ipData.install_point_id,
                    name: ipData.name,
                    tags: (!ipData.tags_dados || ipData.tags_dados.length === 0) && list_points.length > 1000 ? this.noSectorArr : ipData.tags_dados,
                    type: !ipData.macrometer ? ipData.type : 'macro',
                    index: i++,
                    macrometer: ipData.macrometer
                  });
                ipAlreadyAccounted.add(ipData.install_point_id);
              }
              
              this.products = _.cloneDeep(this.places);
              this.backupInstallPointsData = _.cloneDeep(this.places);
              this.centralize();

            }
          })
          .catch(() => {
            loader.hide();

            if (!this.empresa) {
              this.showErrorFiltro()
            } else {
              this.showErrorSearch()
            }
          });

        if (this.tempMeter) {
          this.$refs.filter.filterValue = this.tempMeter;
        }

        setTimeout(() => {
          this.emitter.emit('event-show-multiselect', true);
        }, 2000);

        loader.hide();

        if (this.products && CLIENTS_LIST_TAG_SELECTED.includes(store.client_id)) {
          this.radioFilter = this.products.length > 1000 ? "tags" : "name";
          if (this.listTags.length > 0) {
            let tagSelected = this.listTags[0];
            tagSelected.selected = false;
            setTimeout(() => {
              this.selectMonomer(tagSelected);
            }, 500);
          }
        }
        
      } else if (data) {
        try {
          // this.selectSensor(data.type)

          const batteryAndAlarmsData = await getBatteryAndAlarms(data.device_id).then((r) => r.data);
          this.selectedCountries = { ...data }
          this.selectedCountries.battery_level = batteryAndAlarmsData.battery_level;
          this.selectedCountries.alarm_boundaries = batteryAndAlarmsData.alarm_boundaries;
          this.selectedCountries.alarms = batteryAndAlarmsData.alarms;
        } catch {
          loader.hide();
          this.showErrorServer();
        }

        this.getGeral();
        loader.hide();
        this.showMap = true;
      }
    },
    async filterMapTag() {
      const loader = $loading.show({});
      let payload = { 'client_id': store.client_id };

      await installPointsData(payload)
        .then(async (response) => {
          let list_points = response.data;
          
          this.iconDevicesClient = [...new Set(_.cloneDeep(response.data.map(({ type, macrometer }) => macrometer ? 'macro' : type)))];

          if (list_points.length) {
            this.places = [];
            this.products = [];
            const ipAlreadyAccounted = new Set()

            for (let i in list_points) {
              const ipData = list_points[i];
              const repeatedIP = ipAlreadyAccounted.has(ipData.install_point_id);

              ipData.lat = repeatedIP
                ? (parseFloat(`${ipData.lat}`.substr(0, 9)) + 0.000004)
                : (parseFloat(`${ipData.lat}`.substr(0, 9)) - 0.000004);

              ipData.long = repeatedIP
                ? (parseFloat(`${ipData.long}`.substr(0, 9)) + 0.000004)
                : (parseFloat(`${ipData.long}`.substr(0, 9)) - 0.000004);

              this.places.push({
                device_id: ipData.device_id,
                latPair: parseFloat(ipData.lat),
                lngPair: parseFloat(ipData.long),
                flagGroup: repeatedIP,
                lat: ipData.lat,
                lng: ipData.long,
                id: ipData.install_point_id,
                name: ipData.name,
                tags: (!ipData.tags_dados || ipData.tags_dados.length === 0) && list_points.length > 1000 ? this.noSectorArr : ipData.tags_dados,
                type: !ipData.macrometer ? ipData.type : 'macro',
                index: i++,
                index_device: i,
                macrometer: ipData.macrometer
              });
              ipAlreadyAccounted.add(ipData.install_point_id);
            }

            this.products = _.cloneDeep(this.places);
            this.backupInstallPointsData = _.cloneDeep(this.places);
          }
        })
        .catch(() => {
          loader.hide();

          if (!this.empresa) {
            this.showErrorFiltro()
          } else {
            this.showErrorSearch()
          }
        });

      if (this.tempMeter) {
        this.$refs.filter.filterValue = this.tempMeter;
      }

      setTimeout(() => {
        this.emitter.emit('event-show-multiselect', true);
      }, 2000);

      if (this.checkTypes) {
        this.centralize();
        this.filterDevicesByAlarmsOrReadings();
      }

      if (!this.checkTypes) {
        this.resetSelectedCountries();
        this.centralize();
      }

      loader.hide();
    },
    aMonthAgo() {
      const dateStart = new Date();
      return dateStart.getTime() - 30 * 24 * 3600 * 1000;
    },
    async getGeral() {
      this.dataF = null;
      this.dataN = null;
      this.dataP = null;
      this.dataM = null;
      this.tabF = true;
      this.tabN = true;
      this.tabP = true;
      this.tabM = true;
      this.rangeStart = null;

      if (this.selectedCountries) {
        let dateRange = moment(this.range.start).subtract(1, 'year');
        let data = {
          "start_date": dateRange,
          "final_date": `${this.range.end}T23:59:59.999Z`,
          "device_id": this.selectedCountries.device_id
        };

        const allEvents = await commmunication(data)
          .catch(() => this.showErrorServer('Erro ao carregar dados de conectividade do dispositivo!'));

        const aux = allEvents.data.data;
        const xx = {};
        if (aux) {
          Object.entries(aux).forEach(([k, v]) => {
            let aa = (Date.parse(k) / 1000) + (new Date(k).getTimezoneOffset() * 60);
            xx[aa] = v;
          });
        }

        if (this.selectedCountries.type == "flow" && Object.keys(xx).length) {
          this.dataF = xx;
          this.tabF = false;
          this.tabIndex = 0;
        }
        else if (this.selectedCountries.type == "pressure" && Object.keys(xx).length) {
          this.dataP = xx;
          this.tabP = false;
          this.tabIndex = 0;
        }
        else if (this.selectedCountries.type == "noise" && Object.keys(xx).length) {
          this.dataN = xx;
          this.tabN = false;
          this.tabIndex = 0;
        }
        else if (this.selectedCountries.type == "macro" && Object.keys(xx).length) {
          this.dataM = xx;
          this.tabM = false;
          this.tabIndex = 0;
        }
      }
    },
    getIndexTab() {
      if (this.tabIndex == 0) {
        this.tabName = "almD";
      }
    },
    newTags(data) {
      this.newTagsUp = data;
    },
    cleanFilter() {
      this.$refs.filter.filterValue = '';
    },
    upMap(data) {
      setTimeout(() => {
        if (this.$refs.myMapRef) {
          this.$refs.myMapRef.$mapPromise
            .then(() => {
              const bounds = new window.google.maps.LatLngBounds()
              if (data.length) {
                data.forEach((ipData) => bounds.extend({ lat: Number(ipData.lat), lng: Number(ipData.lng) }));
                this.$refs.myMapRef.$mapPromise
                  .then((map) => {
                    map.panTo(bounds.getCenter());
                    map.fitBounds(bounds);
                  })
              }
            })
            .catch(function (e) {
              console.log("message", e);
            });
        }
      }, 900);
    },
    selectMonomer: async function (monomer) {
      monomer.selected = !monomer.selected;
      const { selected } = monomer;

      if (selected) {
        this.auxTags.push(monomer.id_name)
        const ipFilteredByTags = this.backupInstallPointsData
          .filter(({ tags }) => {
            if (tags) return tags.some(({ id_name }) => new Set(this.auxTags).has(id_name));
            return false;
          });

        this.products = _.cloneDeep(ipFilteredByTags);
        this.places = _.cloneDeep(ipFilteredByTags);
        this.upMap(_.cloneDeep(ipFilteredByTags));
      } else if (!selected) {
        this.auxTags = this.auxTags.filter((id_name) => id_name !== monomer.id_name);

        if (!this.auxTags.length) {
          this.products = _.cloneDeep(this.backupInstallPointsData);
          this.places = _.cloneDeep(this.backupInstallPointsData);
          this.upMap(_.cloneDeep(this.backupInstallPointsData));
        } else {
          const ipFilteredByTags = this.backupInstallPointsData
            .filter(({ tags }) => {
              if (tags) return tags.some(({ id_name }) => new Set(this.auxTags).has(id_name));
              return false;
            });

          this.products = _.cloneDeep(ipFilteredByTags);
          this.places = _.cloneDeep(ipFilteredByTags);
          this.upMap(_.cloneDeep(ipFilteredByTags));
        }
      }
    },
    async listAllTags() {
      //Listagem Tag
      if (this.countTagCall == 0) {
        this.countTagCall++;
        let aux = [];
        let listaTags = [];
        let tempTags = [];
        this.listTags = [];
        const payload = { "client_id": this.isSuper && this.empresa ? this.empresa : this.isSuper && !this.empresa ? store.last_search : store.client_id };
        let noSector = {
            "client_id": store.client_id,
            "id_name": "ntag",
            "name": "NTAG",
            "color": "black",
            "tag_master": null
        };
        if (payload.client_id) {
          await listTag(payload)
            .then((response) => {
              aux = response.data
              if (aux.length > 10) {
                aux.push(noSector);
              }
              const seen = new Set(); 
              for (let i = 0; i < aux.length; i++) {
                if (!seen.has(aux[i].id_name)) {
                  seen.add(aux[i].id_name); 
                  listaTags = {
                    name: aux[i].name,
                    color: aux[i].color,
                    id_name: aux[i].id_name,
                    selected: false,
                  }
                  tempTags.push(listaTags)
                }
              }
            })
          if (!this.isSuper) {
            this.countTagCall--;
          }
          this.listTags = tempTags;
        }
      }
      else {
        this.countTagCall--;
      }
    },
    async getAlarmsCount(data) {
      const aux = {
        "client_id": data
      }
      alarmsCount(aux)
        .then((response) => {
          this.flowDevicesCommissioning = response.data.flowActiveDevices;
          this.countDevicesCommissioning = response.data.totalActiveDevices;
          this.pressureDevicesCommissioning = response.data.pressureActiveDevices;
          this.alarmsAllResp = response.data;
          const types = response.data.alarms.types;
          let aa = [];
          let bb = [];
          let cc = [];
          types.removal.forEach(a => {
            aa.push(a);
          })
          types.tampering.forEach(b => {
            bb.push(b);
          })
          types['cable-cut'].forEach(c => {
            cc.push(c);
          })
          let aux = [...aa, ...bb, ...cc];
          this.allFrauds = [...new Set(aux)];
          let arrAlarmsCount = [{
            "name": "Fraude",
            "name_orig": "typesOfFraud",
            "value": this.allFrauds.length,
            "icon": "icone_sensor_fraude_vermelho"
          }, {
            "name": "Hidrômetro Parado",
            "name_orig": "meter-locked",
            "value": response.data.alarms.types['meter-locked'].length,
            "icon": "icone_hdro_parado_vermelho"
          }, {
            "name": "Baixa Comunicação",
            "name_orig": "lowConnectivity",
            "value": response.data.lowConnectivity.length,
            "icon": "icone_baixa_conectividade_vermelho"
          }, {
            "name": "Vazamento Interno",
            "name_orig": "direct-leakage",
            "value": response.data.alarms.types['direct-leakage'].length,
            "icon": "icone_vazamento_interno_vermelho"
          }
          ]

          this.allAlarmsCount = arrAlarmsCount;
        })
        .catch((error) => {
          this.showErrorAlarm(error.message);
        });
    },
    selectSensor(data) {
      if (data.length === 0) {
        data = null;
      }
      if (data && data.length > 0) this.emitter.emit('filter-reset')
      this.checkTypes = data;
    },
    checkAlarmsPress(data) {
      this.checkTypesAlarms = data;
    },
    async getCardsAlarms(data) {
      const { name_orig } = data;
      const { alarms, lowConnectivity } = this.alarmsAllResp;

      let alarmedFilteredDevices = [];

      if (name_orig === 'lowConnectivity' && lowConnectivity.length) {
        const lowConnectivitySet = new Set(lowConnectivity);
        alarmedFilteredDevices = _.cloneDeep(this.backupInstallPointsData
          .filter((ipData) => lowConnectivitySet.has(ipData.device_id)));

      } else if (name_orig === 'typesOfFraud') {
        const { removal, tampering } = alarms.types;
        const cableCut = alarms.types['cable-cut'];
        const typesOfFraud = new Set([...removal, ...tampering, ...cableCut])

        alarmedFilteredDevices = _.cloneDeep(this.backupInstallPointsData
          .filter((ipData) => typesOfFraud.has(ipData.device_id)))

      } else {
        const clickedAlarm = new Set(alarms.types[name_orig]);
        alarmedFilteredDevices = _.cloneDeep(this.backupInstallPointsData
          .filter((ipData) => clickedAlarm.has(ipData.device_id)));
      }

      if (alarmedFilteredDevices.length) {
        this.products = _.cloneDeep(alarmedFilteredDevices);
        this.places = _.cloneDeep(alarmedFilteredDevices);
      } else {
        this.showErrorDeviceAlarm()
      }
    },
    resetFilter() {
      this.checkTypes = null;
      this.range = this.rangeBack;
      this.emitter.emit('filter-reset')
      this.verifyDate = false;
      this.medAlarme = '';

      this.products = _.cloneDeep(this.backupInstallPointsData);
      this.places = _.cloneDeep(this.backupInstallPointsData);
      this.showResultAgreg = false;

      this.resetSelectedCountries();

      this.centralize();
    },
    resultAgreg() {
      let aux = [];
      this.products.map(({ type }) => {
        if (type) aux.push(type);
      })

      if (!aux.includes("pressure" || "noise")) {
        if (this.checkTypes) {
          this.showResultAgreg = true;
          this.dataMapAgreg = _.cloneDeep(this.places);
          this.centralize();
        } else {
          this.showErrorDevice();
        }
      } else {
        this.showErrorDeviceChoose();
      }
    },
    async filterDevicesByAlarmsOrReadings() {
      if (this.medAlarme === 'alarmes' && !this.checkTypesAlarms.length) {
        this.showErrorServer('Por favor selecione pelo menos um alarme para filtragem')
        return;
      }

      const loader = $loading.show({});
      this.resetSelectedCountries();

      if (!this.medAlarme) {
        this.products = _.cloneDeep(this.backupInstallPointsData.filter(({ type }) => type === this.checkTypes));
        this.places = _.cloneDeep(this.backupInstallPointsData.filter(({ type }) => type === this.checkTypes));
        this.centralize();
        loader.hide();
        return
      }

      const client_id = this.empresa;
      store.id = this.empresa;
      let type = this.checkTypes;

      let payload = { client_id, device_type: type === 'macro' ? 'flow' : type };

      if (this.checkTypesAlarms.length) {
        payload.alarms = this.checkTypesAlarms;
      } else if (this.medAlarme === 'medicao') {
        if (!this.verifyDay()) {
          loader.hide();
          return;
        }
        payload.from = this.range.start;
        payload.to = this.range.end;
      }

      await getFilterDevicesByAlarmsOrReadings(payload)
        .then((filterResponse) => {
          const tempProducts = _.cloneDeep(this.backupInstallPointsData.filter(({ device_id }) => filterResponse.data.includes(device_id)));
          const tempPlaces = _.cloneDeep(this.backupInstallPointsData.filter(({ device_id }) => filterResponse.data.includes(device_id)));
          this.products = tempProducts.filter(item => item.type === this.checkTypes);
          this.places = tempPlaces.filter(item => item.type === this.checkTypes);
        })
        .catch(() => {
          loader.hide();

          if (this.empresa === null) {
            this.showErrorFiltro()
          } else {
            this.showErrorSearch()
          }
        })

      loader.hide();
    }
  },
  async mounted() {
    const {client_id, type_user, show_header, last_search} = store;

    if (!show_header && type_user === "1") {
      setTimeout(() => {
        this.emitter.emit('my-event-select-client', client_id)
      }, 300);
    }

    this.rangeBack = this.range;
    this.tabName = "almD";
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timeZoneValue = moment().tz(timeZone).format('z')
    store.local = Math.abs(parseInt(timeZoneValue));
    store.local_country = Intl.DateTimeFormat().resolvedOptions().locale;
    if (this.isSuper && last_search.length > 0) {
      this.filterMap()
      this.listAllTags();
    } else if (!this.isSuper) {
      this.filterMap();
      this.listAllTags();
    }

    this.controleCollpaseFiltro()
  },
  computed: {
    showNav() {
      return store.show_header;
    },
  },
  created() {
    this.emitter.on('my-event-select-client', (data) => {
      if (data) {
        store.last_search = data;
        store.client_id = data;
        setTimeout(() => this.checkMulti = !this.checkMulti, 50);
      }
    });
    this.emitter.on('my-event-add-tag', () => {
      this.filterMapTag();
      this.listAllTags();
    });
    this.emitter.on('my-event-remove-tag', () => {
      this.filterMapTag();
      this.listAllTags();
    });
    this.emitter.on('my-event-select-multi', (data) => {
      this.empresa = data;
      store.client_id = data;
      store.last_search = data;
      this.listAllTags();
    });
    this.emitter.on('my-event-get-pins-func', () => {
      this.listAllTags();
      this.filterMapTag();
    });
    this.emitter.on('event-to-clean-filter', () => {
      this.resetFilter();
      this.filterMap();
    });
  },

  watch: {
    tabIndex: function () {
      this.getIndexTab();
    },
    selectedCountries: async function (selectedCountries) {
      const { device_id } = selectedCountries;

      if (device_id) {
        const batteryAndAlarmsData = await getBatteryAndAlarms(device_id)
          .then((r) => r.data)
          .catch(() => this.showErrorServer('Erro ao carregar informações do dispositivo!'));

        selectedCountries.battery_level = batteryAndAlarmsData.battery_level;
        selectedCountries.alarm_boundaries = batteryAndAlarmsData.alarm_boundaries;
        selectedCountries.alarms = batteryAndAlarmsData.alarms;

        this.getGeral();
      }
      this.listAllTags();
    },
    checkMulti: function () {
      this.resetFilter();
      this.filterMap();
      this.listAllTags();
      this.auxTags = [];
      this.products = [];
    },
    medAlarme: function (newValue, oldValue) {
      if ((newValue && oldValue) && (newValue !== oldValue)) {
        this.emitter.emit('reset-checked-alarms');
      }
    }
  },

  setup() {
    const myMapRef = ref(null);

    const toast = useToast();
    const showSuccess = () => {
      toast.add({
        severity: "success",
        summary: "Informações do Ponto de Instalação salvas com sucesso!",
        life: 3000,
      });
    };
    const showErrorServer = (errorMessage) => {
      toast.add({ severity: 'error', summary: 'Error', detail: errorMessage || " Erro ao acessar o servidor!", life: 2500 });
    }
    const showErrorAlarm = () => {
      toast.add({ severity: 'error', detail: " Erro ao acessar o servidor!", life: 2500 });
    }
    const showErrorFiltro = () => {
      toast.add({ severity: 'error', summary: 'Error', detail: " Por favor, selecione um cliente e tente novamente.", life: 2500 });
    }
    const showErrorSearch = () => {
      toast.add({ severity: 'error', summary: 'Error', detail: "Houve um erro na pesquisa. Por favor, tente novamente.", life: 2500 });
    }
    const showErrorDevice = () => {
      toast.add({ severity: 'error', summary: 'Error', detail: "Nenhum tipo de dispositivo selecionado.", life: 2500 });
    }
    const showErrorDeviceAlarm = () => {
      toast.add({ severity: 'error', detail: "Não há nenhum dispositivo que tenha disparado o alarme selecionado.", life: 2500 });
    }
    const showErrorDeviceChoose = () => {
      toast.add({ severity: 'error', summary: 'Atenção !', detail: "Selecione o dispositivo do tipo Vazão e em seguida, clique em FILTRAR.", life: 2500 });
    }

    return {
      showSuccess,
      showErrorSearch,
      showErrorFiltro,
      showErrorServer,
      showErrorDevice,
      showErrorDeviceChoose,
      showErrorAlarm,
      showErrorDeviceAlarm,
      myMapRef,
    };
  },
});
</script>

<style src="@vueform/multiselect/themes/default.css">
.multiselect {
  width: 25% !important;
}
</style>
<style src="primevue/resources/primevue.min.css"></style>
<style src="primevue/resources/themes/saga-blue/theme.css"></style>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

#cardAlarmes {
  width: max-content;
  min-width: 15rem
}

.gray {
  background: gray;
  color: white;
  margin-right: 0.5rem !important;
}

.brown {
  background: brown;
  color: white;
  margin-right: 0.5rem !important;
}

.pink {
  background: pink;
  color: white;
  margin-right: 0.5rem !important;
}

.purple {
  background: purple;
  color: white;
  margin-right: 0.5rem !important;
}

.orange {
  background: orange;
  color: white;
  margin-right: 0.5rem !important;
}

.aqua {
  background: aqua;
  color: black;
  margin-right: 0.5rem !important;
}

.red {
  background: red;
  color: white;
  margin-right: 0.5rem !important;
}

.green {
  background: green;
  color: white;
  margin-right: 0.5rem !important;
}

.blue {
  background: blue;
  color: white;
  margin-right: 0.5rem !important;
}

.yellow {
  background: yellow;
  color: black;
  margin-right: 0.5rem !important;
}

.hideInput {
  pointer-events: none;
}

.btn-filtrar {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  margin: .5rem 0rem;
  width: 89%;
  font-weight: 400;
  height: 42px;
}

.btn-limpar {
  border-color: #000000;
  background-color: #000000;
  color: white;
  margin: .5rem 0rem;
  width: 89%;
  font-weight: 400;
  height: 42px;
}

.btn-pos_filtrar {
  border-color: #6eff94;
  background-color: white;
  color: black;
  margin: 0rem 0rem;
  width: 89%;
  font-weight: 400;
  height: 42px;
}

.btn-outline-success {
  border-color: #6eff94;
  color: black;
}

.btn-outline-success:hover {
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
}

.btn-check:checked+.btn-outline-success {
  background-color: #6eff94;
  border-color: #6eff94;
  color: black;
}

.px-2 {
  width: 95%;
}

.textDate {
  margin: 0.7rem 0rem 0.5rem 0.5rem;
  text-align: start;
  margin-left: 17px;
  margin-bottom: 0px;
}

.textDate2 {
  margin: -1.2rem 0rem 0.5rem 0rem;
  text-align: center;
  color: #4d7ec5e6;
  font-weight: 500;
  font-size: 20px;
}

.input-data {
  width: 89%;
  font-size: 16px;
}

.btn-detalhamento {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 1;
  font-size: 14px;
  width: 5rem;
  height: 1.5rem;
  justify-self: right;
  align-self: flex-end;
}

.main_header {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  height: -webkit-fit-content;
  height: fit-content;
  margin: -1rem 0rem 0rem 0rem;
  background-color: #dee2e6;
  padding-left: .5rem;
  padding-top: 1rem;
}

.detalhamento {
  display: flex;
  margin-top: 1rem;
  justify-content: space-around;
}

p,
h3 {
  margin-bottom: 0px;
  color: black;
}

.mmm {
  width: 98.5%;
  height: 72vh;
  border-radius: 3%;
  overflow: hidden;
  margin: 0.5rem 0.5rem
}

.mmm_b {
  width: 98.5%;
  height: 86vh;
  border-radius: 3%;
  overflow: hidden;
  margin: 0.5rem 0.5rem
}

.map-device-selecetd {
  width: 95%;
  height: 48vh;
  border-radius: 3%;
  overflow: hidden;
  margin-left: 2.5%;
  border: 4px solid white;
}

.map-device-selecetd-macro {
  width: 95%;
  height: 80vh;
  border-radius: 3%;
  overflow: hidden;
  margin-left: 2.5%;
  border: 4px solid white;
}

.mapGrafico {
  display: flex;
  margin-top: 2.5rem;
}

label {
  margin-left: 0.3rem;
}

.p-listbox {
  background: #ffffff;
  color: #495057;
  border: 0px solid #ced4da;
  border-radius: 3px;
}

.p-listbox .p-focus {
    box-shadow: none; 
    outline: none; 
}

.pontosInstall {
  margin-top: 3rem;
  height: 93vh;
  padding-top: 1rem;
  background-color: white;
  text-align: center;
}

.numPontoInstal {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
}

.fontListPontoInstall {
  color: #4d7ec5e6;
  font-weight: bold;
}

.levelBat {
  width: 53px;
  height: 36px;
  margin-block: auto;
  margin: 0.3rem 1.5rem;
}

.mapaGrafico {
  display: flex;
  margin-top: 0.5rem;
  z-index: 0;
}

.custom {
  width: 100%;
  height: 73vh;
}

.customResultadoAgregado {
  width: 100%;
  padding-top: 0.4rem;
  height: 84vh;
}

.tabs {
  width: 95%;
  margin: 10px 0px .7rem 2.5%;
}

.imgSize {
  width: 60%;
  height: 100%;
}

.allScreen {
  display: flex;
  height: 91vh;
  flex-wrap: nowrap;
}

.VerComoList {
  display: none;
}

.listBox {
  width: 100%;
  height: 100%;
}

.alternative {
  margin-top: 50px;
}

.btnSel {
  text-align: center;
  background: #fff;
}

.btnSel:active {
  background: #6EFF94;
}

.style-btnSel {
  width: 100%;
}

.btn-group {
  background-color: white;
}

@media only screen and (min-height: 800px) {
  .mmm {
    height: 80.7vh;
  }

  .mmm_b {
    height: 90.4vh;
  }

  .pontosInstall {
    height: 95vh;
  }

  .custom {
    height: 81.2vh;
  }

  .customResultadoAgregado {
    height: 90vh;
  }

  #resultadoAgregado {
    height: 87vh;
  }

  .cardStyle {
    margin-right: 3.5rem !important;
  }
}

@media only screen and (min-width: 581px) {
  .alternative {
    display: none;
  }
}

@media only screen and (max-width: 1370px) {
  .input-data {
    font-size: 14px;
  }
}

@media only screen and (max-width: 580px) {
  .main_header {
    width: 100%;
  }

  .pontosInstall {
    height: 104vh;
    width: 100%;
    text-align: -webkit-center;
  }

  .allScreen {
    display: none;
  }

  .VerComoMap {
    display: none;
  }

  .VerComoList {
    padding-top: 1rem;
    display: revert;
    width: 95%;
  }

  .imgSize {
    width: 100%;
    height: 100%;
    max-height: 125px;
  }

  .mapaDisplay {
    display: none;
  }

  .graficoDisplay {
    width: 100%;
  }
}
</style>
<style lang="scss" scoped>
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/close-o.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/select-o.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/move-right.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/push-chevron-right.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/push-chevron-left.css');

.mapaResultadoAgregado {
  border: 4px solid #fff;
  height: 83vh;
  width: 96.5%
}

#resultadoAgregado {
  margin-top: 1.5rem;
  height: 87vh;
}

#app {
  height: 100vh;
  background-color: #dee2e6;
}

.gg-push-chevron-left {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, .7));
  width: 10px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px;
}

.gg-push-chevron-right {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, .7));
  width: 5px;
  height: 11px;
  border: 2px solid transparent;
  border-radius: 100px;
  padding-left: 4.2rem;
  margin-left: -3rem;
}

.openbtn {
  margin-left: -2.6em;
  border: thin;
  scale: 2;
  padding-top: 5rem;
  background: #dee2e6;
  height: 56vh;
}

.openbtn_inicio {
  border: thin;
  scale: 2;
  position: fixed;
  background: #dee2e6;
  margin-top: 0rem;
  height: fit-content;
  padding-right: 0rem;
  width: 1.3rem;
}

.closebtn_inicio {
  border: thin;
  scale: 2;
  position: fixed;
  background: #dee2e6;
  margin-top: 0rem;
  height: fit-content;
  padding-right: 0rem;
}

.collapseFilterClass {
  background-color: #dee2e6;
  cursor: pointer;
}

.marginIcon {
  width: 5%;
  border: 0px solid;
  display: unset;
  border-radius: 0px;
  background-color: #dee2e6;
  vertical-align: middle;
}

.btn-outline-success {
  border-color: #6EFF94;
  color: black;
  background-color: white;
}

.btn-outline-success:hover {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
}

.btn-check:checked+.btn-outline-success {
  background-color: #6EFF94;
  border-color: #6EFF94;
  color: black;
  z-index: 0;
}

::v-deep(.p-tabview) {
  .p-tabview-panels {
    border-bottom-right-radius: 3% 7%;
    border-bottom-left-radius: 3% 7%;
    padding: .5rem;
    height: 11rem;
    overflow-y: auto;
  }

  .my-scroll-container {
    height: 100px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #6eff94;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6eff94;
  }

  @media only screen and (min-height: 800px) {
    .p-tabview-panels {
      border-bottom-right-radius: 3% 7%;
      border-bottom-left-radius: 3% 7%;
      padding: 1rem;
      height: 24vh;
    }

    .my-scroll-container {
      height: 190px;
    }
  }
}

::v-deep(.p-tabview) {
  .p-tabview-nav {
    .p-highlight {
      .p-tabview-nav-link {
        background: #6EFF94;
        border-color: #6EFF94;
        color: #000;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
      }
    }
  }
}

::v-deep(.p-tabview) {
  .p-tabview-nav {
    height: 38px;

    .p-tabview-nav-link {
      height: 38px;
      padding: 0.5rem;

      .p-tabview-title {
        font-weight: 400;
        font-size: 15px;
        font-family: "Roboto", sans-serif;
        color: black;
      }
    }
  }

  @media only screen and (min-height: 800px) {
    .p-tabview-nav {
      height: 65px;
      padding: 1rem;

      .p-tabview-title {
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
      }
    }
  }
}

::v-deep(.custom) {
  .p-scrollpanel-bar {
    background-color: #6EFF94;
    border: 0 none;
  }
}

::v-deep(.customResultadoAgregado) {
  .p-scrollpanel-bar {
    background-color: #6EFF94;
    border: 0 none;
  }
}

::v-deep(.box_indicadores) {
  .p-card-body {
    padding: 0.2rem .4rem .5rem .5rem;
  }

  .p-card-content {
    padding: 0rem .2rem .2rem .2rem;
  }

  .p-card-title {
    font-family: "Roboto", sans-serif;
    color: #000;
    font-weight: 400;
    margin: 0rem;
    padding-left: 1rem;
  }
}

::v-deep(.p-listbox) {
  .p-listbox-item {
    border-top: 1px solid black;
  }

  .p-disabled,
  .p-disabled * {
    pointer-events: auto;
  }

  .p-listbox-list-wrapper {
    max-height: 100%;
  }

  @media only screen and (min-height: 800px) {
    .p-listbox-list-wrapper {
      max-height: 128%; //filtro fechado
    }
  }
}

@media only screen and (min-width: 1360px) {
  #info-pi {
    padding: 0rem 0rem 0rem 1.5rem;
    width: 85%
  }
}

@media only screen and (max-width: 1360px) {
  #info-pi {
    padding: 0rem 0rem 0rem 0.9rem;
    width: 585px;
  }
}

@media only screen and (max-width: 1316px) {
  #info-pi {
    padding: 0rem 0rem 0rem 0.7rem;
    width: 565px;
  }
}

::v-deep(.p-multiselect) {
  .p-multiselect-label-container {
    .p-multiselect-label {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.sidebar {
  height: 93%;
  width: 0;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  margin-top: 3rem;
  transition: all .3s linear;
}

.sidebar-hidden-nav {
  height: 97%;
  width: 0;
  position: fixed;
  z-index: 0;
  top: -45px;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  margin-top: 3rem;
  transition: 0.5s;
}

@media only screen and (min-height: 800px) {
  .sidebar {
    height: 95%;
  }

  .listBox {
    height: 58vh;
  }

  .mapaResultadoAgregado {
    height: 89vh;
  }

  #resultadoAgregado {
    height: 91vh;
  }
}

.sidebar .closebtn {
  position: absolute;
  right: 0px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left .5s;
  padding: 20px;
}

::v-deep(.p-card) {
  .p-card-title {
    font-family: "Roboto", sans-serif;
    color: #000;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: 'center';
  }
}

.device-type-icon {
  width: 30px;
  height: 30px;
}

.device-type-icons-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  width: 60%;
}

.cardStyle {
  width: 15rem;
  height: 6rem;
  margin-right: 0.5rem;
  z-index: 0;
  border-radius: 15px;
}

.gridInst {
  display: grid;
  padding-left: .3rem;
}

.codInst_grid {
  grid-column: 1 / span 2;
  grid-row: 1;
  color: #8086C0;
  font-weight: 400;
}

.type_grid {
  grid-column: 1;
  grid-row: 2 / span 2;
  width: 35px;
  height: 35px
}

.btnDetail_grid {
  grid-column: 2;
  grid-row: 2 / span 2;
}
</style>
