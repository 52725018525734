<template>
    <Toast />
  <div class="head-btn">
    <h2>Pontos de Instalação</h2> 
    <!-- <div style="justify-items: self-end;">
        <button class="btn btn-login" @click="exportCSV($event)"> Exportar </button>
    </div> -->
    <div style="justify-items: self-end; width: 200px">
      <CompRelatorios :flagScreen="false" />
    </div>
  </div>
  <div v-if="testLoad">
    <div class="card" style="border-radius:15px">
        <div class="grid formgrid">
            <div class="field col-12 md:col-6 md:pr-6 pr-0">
                <div class="custom-skeleton p-4">
                    <Skeleton width="100%" height="200px"></Skeleton>
                    <div class="flex justify-content-center mt-3">
                        <Skeleton width="100%" height="80px"></Skeleton>
                    </div>
                    <div class="flex justify-content-center mt-3">
                        <Skeleton width="100%" height="80px"></Skeleton>
                    </div>
                    <div class="flex justify-content-center mt-3">
                        <Skeleton width="100%" height="80px"></Skeleton>
                    </div>
                    <div class="flex justify-content-center mt-3">
                        <Skeleton width="100%" height="200px"></Skeleton>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="tableBox" v-else>
    <DataTable :value="listSensores" ref="dt" v-model:filters="filters" filterDisplay="menu" :paginator="true" :rows="50" class="paginator-custom" v-model:expandedRows="expandedRows"
    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
    scrollable scrollHeight="75vh" 
    :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll" :exportFilename="'Listagem Pontos de Instalação - ' + trad_name + timeNow"
    currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords}"
    :globalFilterFields="['levelBat']"
    @click="verPhoto(this)"
    >
    <template #empty>Sem Dados Disponíveis</template>
      <Column :expander="true" />
      <Column field="name" header="Código de Instalação" :showFilterOperator="false" :showAddButton="false" :showFilterMatchModes="false">
      <template #body="{data}" >
        {{data.name}}
      </template>
      <template #filter="{filterModel}" showFilterOperator="false">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisa Ponto"/>
      </template>
      <template #filterclear="{filterCallback}">
          <Button type="button" icon="pi pi-times" @click="filterCallback()" class="p-button-secondary"></Button>
      </template>
      <template #filterapply="{filterCallback}">
          <Button type="button" icon="pi pi-check" @click="filterCallback()" class="p-button-success"></Button>
      </template>
      </Column>
      <Column field="lat" header="Latitude" style="display:none"></Column>
      <Column field="long" header="Longitude" style="display:none"></Column>
      <Column field="serial_number" header="ID do Dispositivo" :showFilterOperator="false" :showAddButton="false" :showFilterMatchModes="false" >
      <template #body="{data}" >
        {{data.serial_number}}
      </template>
      <template #filter="{filterModel}" showFilterOperator="false">
          <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Pesquisa Ponto"/>
      </template>
      <template #filterclear="{filterCallback}">
          <Button type="button" icon="pi pi-times" @click="filterCallback()" class="p-button-secondary"></Button>
      </template>
      <template #filterapply="{filterCallback}">
          <Button type="button" icon="pi pi-check" @click="filterCallback()" class="p-button-success"></Button>
      </template>
      </Column>
      <Column field="type" header="Tipo do Dispositivo" :showAddButton="false" :showFilterOperator="false" :showFilterMatchModes="false">
        <template #body="{ data }">{{ data.type}}</template>
        <template #filter="{filterModel}"  showFilterOperator="false">
          <Dropdown v-model="filterModel.value" :options="typeDevices" placeholder="Pesquisa Tipo">
            <template #option="slotProps">
              <span>{{slotProps.option}}</span>
            </template>
          </Dropdown>
      </template>
      <template #filterclear="{filterCallback}">
          <Button type="button" icon="pi pi-times" @click="filterCallback()" class="p-button-secondary"></Button>
      </template>
      <template #filterapply="{filterCallback}">
          <Button type="button" icon="pi pi-check" @click="filterCallback()" class="p-button-success"></Button>
      </template>
      </Column>
      <Column field="address" header="Endereço de Instalação" style="display:none">
        <template #body="{ data }">{{ data.address.replace(/null|null-null/g,' ') }}</template> 
      </Column>
      <Column field="created_at" header="Data de Comissionamento" :sortable="true" style="display:none">
        <template #body="{ data }">{{ new Date(data.created_at).toLocaleString("pt-br") }}</template>
      </Column>
      <Column style="display:none" field="tagName" header="Tags">
      </Column>
      <Column field="tag" header="Tags" :showAddButton="false" :showFilterOperator="false" :showFilterMatchModes="false" :exportable="false">
        <template #body="{ data }"> <div v-for="aaa in data.tag" :key="aaa">
             <span  :class="aaa !='' ? 'multiselect-tag is-user' : ''" 
             :style=" aaa !='' && aaa.color != 'yellow' && aaa.color != 'aqua' && aaa.color != 'pink' ? `color: white;background-color: ${aaa.color}; border: 1px solid;`: `color: black;background-color: ${aaa.color}`"
             >{{aaa.label}}</span>
          </div>
        </template>
        <template showFilterOperator="false" #filter>          
          <Multiselect
          class="multiselect-custom"
          :columns="{ container: 12, label: 4, wrapper: 6 }"
          v-model="testeLista"
          placeholder="Pesquisa por tag"
          :options="listTags"
          optionLabel="value" >
          <template #value="slotProps">
                <div class="country-item country-item-value" v-for="option of slotProps.value" :key="option.value">
                   <div
                      class="multiselect-tag is-user"
                      :style=" option !='' && option.color != 'yellow' && option.color != 'aqua' && option.color != 'pink' ? `color: white;background-color: ${option.color}; border: 1px solid;`: `color: black;background-color: ${option.color}`"
                    >
                      <label style="margin:auto">{{ option.value }}</label>
                    </div>
                </div>
                <template v-if="!slotProps.value || slotProps.value.length === 0">
                    Pesquisa por tag
                </template>
            </template>
          <template #option="slotProps">
            <div>
              <span class="badge rounded-pill" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="slotProps.option.value" :style="slotProps.option.color != 'yellow' && slotProps.option.color != 'aqua' && slotProps.option.color != 'pink' ? `background-color: ${slotProps.option.color}; color: white; font-size:14px `:`background-color: ${slotProps.option.color}; color: black;font-size:14px `">{{slotProps.option.value}}</span>
            </div>
          </template>
          </Multiselect>
        </template>
        <template #filterclear="{filterCallback}">
            <Button type="button" icon="pi pi-times" @click="filterCallback(), tagClear()" class="p-button-secondary"></Button>
        </template>
        <template #filterapply="{filterCallback}">
            <Button type="button" icon="pi pi-check" @click="filterCallback(), getTags()" class="p-button-success"></Button>
        </template>
      </Column>
      <Column field="last_communication" dataType="date" header="Última Comunicação" :sortable="true">
       <template #body="{data}">
          {{data.last_communication == 'Aguardando...' ? data.last_communication : formatDate(data.last_communication)}}
        </template>
      </Column>
      <Column field="week_communication" header="Taxa Comunicação (Últimos 7 dias)" style="display:none">
      <template #body="{data}">
        {{data.week_communication}}
      </template>
      </Column>
      <Column field="levelBat" header="Nível da Bateria" :showAddButton="false" :showFilterOperator="false" :showFilterMatchModes="false">
       <template #body="{ data }">
        <img v-if="data.levelBat == 'Bom'" src="../assets/battery-full.png" class='levelBat flag flag-'>
        <img v-if="data.levelBat == 'Regular'" src="../assets/battery.png" class='levelBat flag flag-'>
        <img v-if="data.levelBat == 'Baixo'" src="../assets/battery-empty.png" class='levelBat flag flag-'>
        <span>{{data.levelBat == 'Sem dados' ? data.levelBat : ''}}</span>
       </template>
      <template #filter="{filterModel}"  showFilterOperator="false">
        <Dropdown v-model="filterModel.value" :options="statuses" placeholder="Pesquisa Status">
          <template #option="slotProps">
            <span>{{slotProps.option}}</span>
          </template>
        </Dropdown>
      </template>
      <template #filterclear="{filterCallback}">
          <Button type="button" icon="pi pi-times" @click="filterCallback()" class="p-button-secondary"></Button>
      </template>
      <template #filterapply="{filterCallback}">
          <Button type="button" icon="pi pi-check" @click="filterCallback()" class="p-button-success"></Button>
      </template>
      </Column>
      <Column style="display:none" field="alarms" header="Alarmes">
      </Column>
      <Column style="display:none" field="volume_direto" header="Volume Total Direto(m³)">
        
      </Column>
      <Column field="volume_reverso" header="Volume Total Reverso(m³)" style="display:none">
      </Column>
      <template #expansion="slotProps" @change="testeImg(slotProps.data.devices)">
         <div class="orders-subtable">
             <p><b>Alarmes:</b> {{slotProps.data.alarms}}</p>
             <p><b>Latitude/Longitude:</b> {{slotProps.data.lat}},  {{slotProps.data.long}}</p>
             <p><b>ID do Dispositivo:</b> {{slotProps.data.serial_number}}</p>
             <p v-if="!demo"><b>Endereço:</b> {{slotProps.data.address.replace(/null|null-null/g,' ')}}</p>
             <p><b>Data de Comissionamento:</b> {{new Date(slotProps.data.created_at).toLocaleString("pt-br")}}</p>
             <p v-if="slotProps.data.initial_display"><b>Display Inicial:</b> {{slotProps.data.initial_display}}</p>
             <p v-if="slotProps.data.resolution"><b>Resolução do Display:</b> {{slotProps.data.resolution}}</p>
             <p><b>Taxa Comunicação (Últimos 7 dias):</b> {{slotProps.data.week_communication}}</p>
             <p v-if="slotProps.data.type == 'Vazão'"><b>Volume Total Direto(m³):</b> {{slotProps.data.volume_direto}}</p>
             <p v-if="slotProps.data.type == 'Vazão'"><b>Volume Total Reverso(m³):</b> {{slotProps.data.volume_reverso}}</p>   
             <p v-if="slotProps.data.obs.length > 0"><b>Obs:</b> {{slotProps.data.obs}}</p>   
             <p v-if="slotProps.data.altimeter"><b>Altimetro:</b> {{slotProps.data.altimeter}}</p>          
         </div>
              <div v-if="slotProps.data.device_image != ''" style="margin-inline: auto; display: flex">
                <div style="text-align: center;">
                  <p v-if="slotProps.data.type == 'Vazão'"><b>Foto do Display</b></p>
                  <p v-else><b>Foto do Dispositivo</b></p>
                  <Image v-if="slotProps.data.device_image" :src="url+slotProps.data.device_image" alt="Image" width="200" imageStyle="z-index:0; position:relative"  preview/>
                </div>
                <div style="margin-left:150px; text-align: center;">
                  <p><b>Foto do Conjunto</b></p>
                  <Image v-if="slotProps.data.set_image" :src="url+slotProps.data.set_image" alt="Image" width="200" imageStyle="z-index:0; position:relative" preview/>
                </div>
              </div>
              <div v-else style="margin-inline: auto; display: flex">
                <div style="text-align: center;">
                  <p v-if="slotProps.data.type == 'Vazão'"><b>Foto do Display</b></p>
                  <p v-else><b>Foto do Dispositivo</b></p>
                  <span>Sem Imagem</span>
                </div>
                <div style="margin-left:150px; text-align: center;">
                  <p><b>Foto do Conjunto</b></p>
                  <span>Sem Imagem</span>
                </div>
              </div>
     </template>
    </DataTable>
  </div>
</template>

<script>
import { ref } from 'vue';
import Multiselect from 'primevue/multiselect';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {  listClientComissioning, alarms} from "../services/dashboard.js";
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { store } from "../services/store";
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import moment from "moment-timezone";
moment.locale("en-us");
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import Button from 'primevue/button';
import { listTag } from "../services/crud.js";
import Skeleton from 'primevue/skeleton';
import CompRelatorios from "../components/CompRelatorios.vue";
import Image from 'primevue/image';
import { URL_IMG } from "../services/config";

const battery_full = require("../assets/battery-full.png");

export default {
  components: {
    Multiselect,
    Column,
    DataTable,
    Button,
    Dropdown,
    Toast,
    InputText,
    Skeleton,
    CompRelatorios,
    Image
  },
  data() {
    return {
      battery_full_icon: {
        url: battery_full,
        scaledSize: { width: 40, height: 40, f: "px", b: "px" },
      },
      testLoad: true,
      checkMulti: false,
      products: null,
      selectClient: null,
      dataPhoto: [],
      listTags: [],
      testeLista:[],
      contTags: null,
      filters: {
        'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'tag': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        'serial_number': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
        levelBat: {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
        type: {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
      },
      statuses: ['Baixo','Regular','Bom'],
      typeDevices: ['Pressão', 'Ruído','Vazão'],
      tagName: ['Amarelo','Azul','Cinza','Verde','Vermelho'],
      control: 0,
      url: URL_IMG,
      demo: JSON.parse(localStorage.getItem("userData")).demo,
    }
  },
  watch: {
    contTags: function (){
      if (this.contTags !== null) {
        this.filters.tag.constraints[0].value = "zzzzzzzzzzzz"
        this.filters.tag.constraints[0].matchMode = "notEquals"
      }
    },
    checkMulti: function () {
      if(this.control == 0) {
        this.control ++;
        if(this.$route.path === '/sensores') {
          this.getDevices();
          this.allTagCreated();
        }
      }      
    }
  },
  methods:{
    async allTagCreated() {
      let aux = []
      let listaTags = []
      this.listTags = []
      const client_id = {"client_id":store.client_id}
      await listTag(client_id)
      .then((response) => {
        aux = response.data      
        for (let i = 0; i < aux.length; i++) {
          listaTags = {
            label: aux[i].name,
            value: aux[i].name,
            color: aux[i].color,
          }
          this.listTags.push(listaTags)
        }
      })
    },
    tagClear() {
      this.testeLista = [];
      this.getTags()
    },
    getTags() {
      let selTags = [];
      this.testeLista.map(a=>{
        selTags.push(a.value)
      })

      let res = selTags;
      if(this.contTags == null) {
        this.contTags = res;
      }

      let arrProd = []
      if(res.length > 0) {
        if(res.length > this.contTags.length){
          this.listSensores.map(function(a){
          if(a.tag){
           let cont = 0;
            res.forEach(e => {
              for(let i of a.tag){
                if(i.label.includes(e)){
                  cont++;
                }
              }
            });
            return (res.length == cont)? arrProd.push(a) : '';
          } 
        })
       this.listSensores = arrProd;
        }else{
          this.listSensoresB.map(function(a){
          if(a.tag){
           let cont = 0;
            res.forEach(e => {
              for(let j of a.tag){
                if(j.label.includes(e)){
                  cont++;
                }
              }
              });
              return (res.length == cont)? arrProd.push(a) : '';
            } 
          })
        this.listSensores = arrProd;
        }
      }else{
        this.listSensores = this.listSensoresB;
        this.contTags = null;
      }
    },
    async getDevices() {
      this.listSensores = [];
      this.listSensoresB = [];
      let data = null;
      if(store.type_user == 1) {
        data = store.last_search;
      }else if(store.type_user != 1) {
        data = store.client_id;
      }
      this.testLoad = true;
        if(data) {
          await listClientComissioning(data)
        .then((response) => {        
          this.products = response.data;
          this.control = 0;
          let aux = []
          let aux2 = []
          for (let i = 0; i < this.products.length; i++) {
            aux = this.products[i].tags_dados ? this.products[i].tags_dados : 'Vazio'
            aux2 = this.products[i].tags_dados ?  aux.map(function(a){return {'label':a.name, 'value':a.name, 'color': a.color}}) : 'Vazio'
            for (let n = 0; n < this.products[i].devices.length;n++){
                  const deviceAlarms = this.products[i].devices[n].alarms.map((alarmObj) => alarmObj.pt)
                  const deviceAlarmsString = deviceAlarms.join('; ')
                  const deviceIds = this.products[i].devices[n].id
                  this.listSensores.push({
                  name: this.products[i].name != null ? this.products[i].name :  "Sem dados",
                  install_point_id: this.products[i].devices[n].install_point_id != null ? this.products[i].devices[n].install_point_id : "Sem dados",
                  serial_number: this.products[i].devices[n].serial_number != null ? this.products[i].devices[n].serial_number : "Sem dados",
                  type: this.products[i].devices[n].type  == 'noise' ? 'Ruído' : this.products[i].devices[n].type == 'pressure' ? 'Pressão' : 'Vazão',
                  address: this.products[i].number == null ? this.products[i].address + ", " + this.products[i].city + "-" + this.products[i].state : this.products[i].state == null ? this.products[i].address + ", " + this.products[i].number + " " + this.products[i].city  : this.products[i].address + ", " + this.products[i].number + " " + this.products[i].city + "-" + this.products[i].state,
                  created_at: this.products[i].created_at != null ? this.products[i].created_at : "Sem dados",
                  tag: aux2 != 'Vazio' ? aux2 : '',
                  levelBat: this.products[i].devices[n].batery == 'Ok' ? 'Bom' : this.products[i].devices[n].batery == 'Regular' ? 'Regular' : this.products[i].devices[n].batery == 'Baixa' ? 'Baixo' : 'Sem dados' ,
                  alarms: deviceAlarmsString != '' ? deviceAlarmsString : 'Nenhum alarme foi disparado.',
                  volume_direto: this.products[i].devices[n].type  == 'flow' ? this.products[i].devices[n].volume_direto : '-',
                  volume_reverso: this.products[i].devices[n].type  == 'flow' ? this.products[i].devices[n].volume_reverso : '-',
                  lat: this.products[i].lat,
                  long: this.products[i].long,
                  week_communication: this.products[i].devices[n].week_communication == 'Não calculado' ? 'Sem dados' : this.products[i].devices[n].week_communication+' %',
                  last_communication: this.products[i].devices[n].last_communication != '' ? new Date(this.products[i].devices[n].last_communication): 'Aguardando...',
                  obs: this.products[i].devices[n].obs && this.products[i].devices[n].obs.length > 0 ? this.products[i].devices[n].obs : '',
                  altimeter: this.products[i].devices[n].altimeter ? this.products[i].devices[n].altimeter : null,
                  devices: deviceIds,
                  tagName: this.products[i].tags,
                  initial_display: this.products[i].devices[n].type === 'flow' ? this.products[i].devices[n].initial_display : null,
                  resolution: (this.products[i].meter)? (this.products[i].meter.setup.resolution)? `${((this.products[i].meter.setup.resolution)*1000)} litros/pulso` : 'Sem Dados' : '',
                });
                this.listSensoresB = this.listSensores;
            }
          }
        })
        .catch((err) => {this.showErrorServer(err)
          this.control = 0;
        }
        );
        }
        this.testLoad = false;
    },
    async verPhoto(data) {
      let json = {};
      let week_com = [];
      const all_install_point = data.listSensores;
      const install_point = data.expandedRows;
      if(install_point.length > 0) {
        const index_install_point = all_install_point.findIndex(a => a.serial_number == install_point[(install_point.length - 1)].serial_number)
        if(this.listSensoresB[index_install_point].devices != undefined) {
          await alarms(this.listSensoresB[index_install_point].devices)
          .then((response) => { 
             json={
              'device_image':response.data[0].device_image,
              'set_image':response.data[0].set_image
             }
             week_com = response.data[0].week_communication;
             return json, week_com;
          })
        }
        
        try {
                if(this.listSensores[index_install_point].device_image == undefined && this.listSensores[index_install_point].set_image == undefined) {
                    this.listSensores[index_install_point].device_image = json.device_image;
                    this.listSensores[index_install_point].set_image = json.set_image;
                    this.listSensores[index_install_point].week_communication = `${week_com.toFixed(2)} %`;
                }                   
              } catch (e) {
              throw new Error(`Could not retrieve file from S3: ${e.message}`)
            }
      }
    }
  },
  async mounted() {
    const {client_id, type_user, show_header} = store;

    if (!show_header && type_user === "1") {
      setTimeout(() => {
        this.emitter.emit('my-event-select-client', client_id)
      }, 300);
    }

    this.allTagCreated();
    this.getDevices();
  },
  created() {
    this.emitter.on('my-event-select-client', (data) => {
      if(data){
        store.last_search = data;
        store.client_id = data;
        this.checkMulti = true;
        setTimeout(() => this.checkMulti = false, 10); 
      }
    })
    this.emitter.on('my-event-add-tag', () => {
    this.allTagCreated();

    }),
    this.emitter.on('my-event-remove-tag', () => {
      this.allTagCreated();
    })
    this.emitter.on('my-event-select-multi-obj', (data) => {
      if(data.length > 0) {
        store.last_search = data[0].value;
      }
    })
  },
  setup() {
    const expandedRows = ref([]);
    let listSensores = ref([]);
    let listAllTags = ref([]);

    const formatDate = (value) => {
      return value.toLocaleString("pt-BR");
    };
    const trad_name = store.trading_name
    const timeNow = moment().format('l')
    const dt = ref();
    const exportCSV = () => {
      dt.value.exportCSV();
    };

    const toast = useToast();
    const showErrorServer = () => {
      toast.add({severity:'error', summary: 'Error', detail:" Erro ao acessar o servidor!", life: 2500});
    }
    const listSensoresB = listSensores.value;
    return {listSensoresB,listSensores,listAllTags,exportCSV,dt,trad_name,timeNow,expandedRows,showErrorServer, formatDate}
  }
}
</script>         

<style scoped>
.p-multiselect-panel{
  background: red;
}
h2{
  margin: 1rem
}
.p-button-success{
  background: #6EFF94;
  border-color: #E3F2FD
}
.p-button-success:hover{
  background: #6EFF94 !important;
  border-color: #E3F2FD;
}
.tableBox{
  padding: 0rem;
  box-shadow: 0px 4px 19px 4px rgb(0 0 0 / 20%);
  margin: 1rem;
}
.baixa {
  background: red;
  padding: 15%;
  border-radius: 10%;
  color: #fff;  
}
.regular {
  background: yellow;
  padding: 15%;
  border-radius: 10%;
  color: #fff;  
}
.alta {
  background: green;
  padding: 15%;
  border-radius: 10%;
  color: #fff;  
}
.batSemDados{
  margin-left: 20px;
}
.btn-login {
  border-color: #6EFF94;
  background-color: #6EFF94;
  color: black;
  line-height: 2rem;
  font-size: 1.1rem;
  margin: 1rem;
  width: 9rem;
  font-weight: 450;
}
.levelBat {
  width: 50px;
  height: 29px;
  margin-block: auto;
}
.head-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .multiselect_tags {
    width: 320px;
  }
  .multiselect-tag.is-user {
    padding: 5px 8px;
    border-radius: 22px;
    background: #35495e;
    margin: 3px 3px 8px;
  }

  .multiselect-tag.is-user img {
    width: 18px;
    border-radius: 50%;
    height: 18px;
    margin-right: 8px;
    border: 2px solid #ffffffbf;
  }

  .multiselect-tag.is-user i:before {
    color: #ffffff;
    border-radius: 50%;;
  }

  .user-image {
    margin: 0 6px 0 0;
    border-radius: 50%;
    height: 22px;
  }
  img {
    float: left;
    display: block;
    margin: 25px;
    height: 300px;
  }
/* Expandido */
  .photo_devices:hover {
    height: 600px;
    margin: 0;
  }  
</style>
<style lang="scss" scoped>
 ::v-deep(.p-column-filter) {
    .p-column-filter-menu-button.p-column-filter-menu-button-active{
      background: #6eff94 !important
    }
 }

  ::v-deep(.paginator-custom) {
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
      background: #6EFF94;
      border-color: #E3F2FD;
      color: #495057;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      color: #495057;
      background: #6EFF94;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      color: #495057;
      background: #6EFF94;
    }
  }
  ::v-deep(.p-dropdown) {
    .p-dropdown-items {
      color: black;
    }
  }
  ::v-deep(.p-datatable) {
    .p-datatable-tbody {
      z-index: 0;
      position: relative;
      td{
        flex-wrap: wrap;
      }
    }
    .p-datatable-thead{
      z-index: 0;
    }
  }
  
</style>