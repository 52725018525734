import Keycloak from 'keycloak-js';
import { URL_BASE_KEYCLOAK, CLIENT_ID_KEYCLOAK } from './services/config';

function getListLocalStorageWithRetry(retries = 5, delay = 1000) {
  return new Promise((resolve, reject) => {
    const attempt = (remainingRetries) => {
      const listLocalStorage = localStorage.getItem('realms-list-stt4');
      if (listLocalStorage) {
        resolve(JSON.parse(listLocalStorage));
      } else if (remainingRetries > 0) {
        setTimeout(() => attempt(remainingRetries - 1), delay);
      } else {
        reject(new Error('Unable to retrieve realms-list-stt4 from localStorage'));
      }
    };
    attempt(retries);
  });
}

async function initializeKeycloak() {
  const realms = await getListLocalStorageWithRetry();

  const last_client = localStorage.getItem('last_client');
  let defaultRealm = 'default';

  if (last_client && last_client.length > 0) {
    const lastClientResult = realms?.find(item => item.client === last_client);
    if (lastClientResult) {
      defaultRealm = lastClientResult.realm;
    }
  }

  const path = window.location.pathname;
  const partPath = path.split('/');

  const client = partPath[partPath.length - 1];
  const result = realms?.find(item => item.client === client);
  const realm = result ? result.realm : defaultRealm;

  return new Keycloak({
    url: URL_BASE_KEYCLOAK,
    realm: realm,
    clientId: CLIENT_ID_KEYCLOAK
  });
}

export default initializeKeycloak;
