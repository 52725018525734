<template>
  <body>
    <img class="logo_stattus" src="../assets/LogoStattus4Verde.png" alt="Logo">
    <p class="login-message"> Por favor, aguarde <span class="dots"></span></p>
  </body>
</template>

<script>
import { onMounted, ref } from 'vue';
import { inject } from 'vue';
import { userFindOneInfo } from "../services/login_keyclaok";
import { useLoading } from 'vue-loading-overlay';
import { store } from '../services/store.js';
import { useRouter } from 'vue-router';

const $loading = useLoading();

export default {
  name: 'Intercept',
  setup() {
    const keycloak = inject('keycloak');
    const token = ref(null);
    const router = useRouter();

    const MAX_RETRIES = 2;
    const CLIENT_RETRY_KEY = 'login_retry_count';

    const doLogin = async (email, token) => {
      const loader = $loading.show({});
      try {
        const response = await userFindOneInfo(email, token);
        const path = window.location.pathname;
        const partPath = path.split('/');
        const client = partPath[partPath.length - 1];
        localStorage.setItem('last_client', client);
        save(response, token);
        success(response, token);
        resetRetryCount();
      } catch (error) {
        const retryCount = getRetryCount();
        if (retryCount < MAX_RETRIES) {
          incrementRetryCount();
          reloadPage();
        } 
      } finally {
        loader.hide();
      }
    };

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const save = (data, token) => {
      const userData = {
        client_id: data.data.client_id,
        token: token,
        user_id: data.data.id,
        name: data.data.name,
        type_user: data.data.type_user,
        trading_name: data.data.client.trading_name,
        last_search: '',
        last_search_name: '',
        roles: data.data.roles,
        demo: data.data.demo,
      };
      localStorage.setItem('userData', JSON.stringify(userData));
    };

    const success = (response, token) => {
      store.client_id = response.data.client_id;
      store.token = token;
      store.user_id = response.data.id;
      store.name = response.data.name;
      store.type_user = response.data.type_user;
      store.trading_name = response.data.client.trading_name;
      store.last_search = '';
      store.last_search_name = '';
      store.roles = response.data.roles;
      store.demo = response.data.demo;

      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const module = params.get("module");

      if (store.type_user == 3) {
        router.replace('/listagem/ativacao');
      } else {
        if (!module) {
          router.replace('/dashboard');
        } else {
          store.show_header = false;
          router.replace(module);
        }
      }
    };

    const getRetryCount = () => parseInt(localStorage.getItem(CLIENT_RETRY_KEY) || '0', 10);
    const incrementRetryCount = () => localStorage.setItem(CLIENT_RETRY_KEY, getRetryCount() + 1);
    const resetRetryCount = () => localStorage.removeItem(CLIENT_RETRY_KEY);

    const reloadPage = () => {
      window.location.reload();
    };

    onMounted(() => {
      const path = window.location.pathname;
      const partPath = path.split('/');
      const client = partPath[partPath.length - 1];

      if (keycloak) {
        if (keycloak.authenticated) {
          token.value = keycloak.token;
          const _token = token.value;
          const _email = keycloak.tokenParsed.email;
          const retryCount = getRetryCount();
          if (retryCount < 2) {
            doLogin(_email, _token);
          }
          if (retryCount >= 2) {
            delay(10000).then(() => {
              resetRetryCount();
              doLogin(_email, _token);
            });
          }
        } else {
          const queryString = window.location.search;
          const params = new URLSearchParams(queryString);
          const module = params.get("module");
          if (!module) {
            keycloak.login({ redirectUri: window.location.origin + '/intercept/' + client });
          } else {
            keycloak.login({ redirectUri: window.location.origin + '/intercept/' + client + `?module=${module}` });
          }
        }
      } else {
        console.error('Keycloak instance not found');
      }
    });

    return { token, doLogin };
  }
};
</script>

<style scoped>
body{
  height: 110vh;
  background-image: url('../assets/inteligentes.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.logo_stattus{
  margin: 2rem 2rem 3.5rem 2rem;
  width: 27rem;
  height: 6.5rem;
}
.img_persona{
  height: 9rem;
  position: relative;
  margin-top: -6rem;
}
form{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-body {
  display: flex;
  flex: 1 1 auto;
  padding: 1rem 1rem;
  flex-direction: column;
  align-items: center;
}
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/user.css');
.gg-user {
  display: block;
  transform: scale(var(--ggs,2));
  box-sizing: border-box;
  width: 13px;
  height: 20px;
  color: white;
}
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/lock.css');
.gg-lock {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1.5));
  width: 12px;
  height: 11px;
  border: 2px solid;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom: transparent;
  margin-top: -12px;
  color: white;
}
.bottom-options{
  display: flex;
  margin: 0rem;
  font-size: 66.5%;
}
.btn-login{
  border-color: #6eff94;
  background-color: #6eff94;
  color: black;
  line-height: 2rem;
  font-size: 1.5rem;
  margin:1rem
}
.input-group>.form-control, .input-group>.form-select{
  background-color: #41464b96;
  color: white;
  line-height: 3rem
}
.input-group{
  margin: .5rem 1rem
}
input::placeholder {
  color: #fff;
}
.input-group-text{
  background-color: #37393c;
  padding: 0.375rem 1.2rem;
}
.card{
  border-radius: 2.25rem;
  padding: 1rem;
  margin-bottom: 3rem;
}
.form-check-input{
  background-color: #41464b96;
}
.form-check-input:checked {
  background-color: #6EFF94;
  border-color: #6EFF94;
}
.form-check{
  margin-right: 9.5rem
}
a {
  color: #000000;
  text-decoration: underline;
}
.login-message {
  text-align: center;
  margin-top: 20px;
  font-size: 32px;
  font-weight: 600;
  color: #fff;
}
.dots::after {
  content: "";
  display: inline-block;
  animation: dots 2.5s steps(3, end) infinite;
}

@keyframes dots {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
@media only screen and (max-width: 599px) {
  body{
    height: 110vh;
    text-align: center;
    background-image: url(/img/inteligentes.bb8c352c.jpg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .logo_stattus{
    margin: 2rem 0.5rem 3.5rem 0.5rem;
    width: 95%;
    height: 6.5rem;
  }
}
@media only screen and (min-width: 575px) and (max-width: 875px) { 
  #main_box{
    width: 52%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 865px) { 
  .logo_stattus{
    margin: 2rem 2rem 4.5rem 1rem;
    width: 20rem;
    height: 4.5rem;
  }
}
@media only screen and (min-width: 480px) and (max-width: 599px) { 
  .logo_stattus{
    margin: 2rem 0.5rem 3.5rem 0.5rem;
    width: 80%;
    height: 6.5rem;
  }
}
@media only screen and (min-height: 730px) {
  .logo_stattus{
    margin: 2rem 2rem 8.5rem 2rem;
  }
}
</style>
